import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
const Header = lazy(() => import('./Components/Layout/header'));
const Footer = lazy(() => import('./Components/Layout/footer'));
const CopyRight = lazy(() => import('./Components/Layout/CopyRight'));
const Home = lazy(() => import('./Components/Home/Home'));
const Career = lazy(() => import('./Components/Career/Career'));
const Blog = lazy(() => import('./Components/Blogs/Blog'));
const About = lazy(() => import('./Components/About/About'));
const NotFoundPage = lazy(() => import('./Components/Layout/NotFoundPage'));
const BlogbyId = lazy(() => import('./Components/Blogs/Singlearticle'));
const CareerDetail = lazy(() => import('./Components/Career/DetailPage'));
const Blogbytag = lazy(() => import('./Components/Blogs/articalebytag'));



class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNavItem: "home", // Initialize activeNavItem state
        };
    }

    // Function to handle footer link clicks and update activeNavItem state
    handleFooterLinkClick = (section) => {
        this.setState({ activeNavItem: section });
    };

    render() {
        return (
            <Router>
                <div>
                    <Header activeNavItem={this.state.activeNavItem} />
                    <main>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/career" element={<Career />} />
                            <Route path="/career/:id" element={<CareerDetail />} />
                            <Route path="/blogs/:title/:id" element={<BlogbyId />} />
                            <Route path="/tags/:title/:id" element={<Blogbytag />} />
                            <Route path="/blog" element={<Blog />} />
                           
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                        </Suspense>
                    </main>
                    <Footer onFooterLinkClick={this.handleFooterLinkClick} />
                    <CopyRight />
                </div>
            </Router>
        );
    }
}

export default App;
